<template>
  <div class="home">
    <v-loading :loading="loading" />
    <v-map ref="map"/>
    <v-control show @setting="showSetting = !showSetting" />
    <v-legend :factor="factor" />
    <v-metric-select :factor="factor" :metrics="metricT2" @change="factorChange" />
    <div class="more-factor" @click="jumpWeb()">
      查看更多要素
      <svg-icon :icon-class="'moreFactor'" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { getDataByFactor } from "@/api/weather_hd";
import { allMetrics } from "./config";
import Bus from "@/bus/index";
import VControl from "./Control.vue";
import { TIANJI2_DA, TIANJI2_ND } from "@/config";
import VMap from './Map.vue'
import VMetricSelect from "./MetricSelect.vue";
import { productionMap } from '@/config/MapConfig';
import VLoading from "@/components/Map/Loading.vue";
import VLegend from "@/components/Map/Legend.vue";

export default {
  name: "Home",
  components: {
    VMap,
    VControl,
    VMetricSelect,
    VLoading,
    VLegend,
  },
  data() {
    return {
      showSetting: false,
      allMetrics,
      productionMap,
      startTime: '',
      factor: 'tmp2m'
    };
  },
  computed: {
    ...mapState('map', ['loading']),
    metricT2() {
      return this.allMetrics;
    },
  },
  created() {
    this.clearLayer();
    this.setFactor('tmp2m')
    this.init();
    this.startTime = Date.now();
  },
  methods: {
    ...mapMutations("map", [
      "setFactorData",
      "setCurrentBaseTime",
      "setFactor",
      "setLoading"
    ]),
    jumpWeb() {
      window.open('https://www.tjweather.com/', '_blank');
    },
    /**
     * @description 根据要素获取预测数据
     * @returns {Promise}
     * @author yujie
     */
    async init() {
      this.setLoading(true)
      const data = (await getDataByFactor(this.factor, this.productionMap[this.factor])).filter((item) => {
        if (this.t2Mode === TIANJI2_ND) {
          return item.mode === "late";
        }
        if (this.t2Mode === TIANJI2_DA) {
          return item.mode === "early";
        }

        return item;
      });
      if (!data || !data.length) {
        this.$message("当前要素无预测数据");
        return;
      }
      this.setFactorData(data);
      this.setCurrentBaseTime(data[0].baseTimeModel);
    },
    factorChange(metric) {
      this.clearLayer();
      this.setFactor(metric.id);
      this.factor = metric.id
      this.init();
    },
    clearLayer() {
      Bus.$emit("clearLayer");
      Bus.$emit("removePopup");
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  position: relative;
  .factor {
    cursor: pointer;
    width: 80px;
    height: 38px;
    position: absolute;
    top: 16px;
    left: 24px;
    padding-left: 14px;
    font-size: 14px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5490196078);
    color: #ffffff;
    svg {
      margin-right: 5px;
    }
  }
  .more-factor {
    cursor: pointer;
    width: 100%;
    height: 45px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 14px;
    font-weight: 600;
    bottom: 0;
    background: #0000008c;
    color: #ffffff;
    svg {
      font-size: 21px;
      position: relative;
      top: 2.5px;
      left: 3px;
    }
  }
}
</style>
